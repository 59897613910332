<script>
  import { onMount } from "svelte";
  import Icon from "@iconify/svelte";

  let randomQuote;

  let quotes = [
    "One thing kids like is to be tricked. For instance, I was going to take my nephew to Disneyland, but instead I drove him to an old burned-out warehouse. 'Oh no,' I said, 'Disneyland burned down.' He cried and cried, but I think that deep down he thought it was a pretty good joke. I started to drive over to the real Disneyland, but it was getting pretty late.",
    "If a kid asks where rain comes from, I think a cute thing to tell him is, 'God is crying.' And if he asks why God is crying, another cute thing to tell him is, 'Probably because of something you did.'",
    "To me, clowns aren't funny. In fact, they're kinda scary. I've wondered where this started and I think it goes back to the time I went to the circus and a clown killed my dad.",
    "I can picture in my mind a world without war, a world without hate. And I can picture us attacking that world, because they'd never expect it.",
    "I remember how my great-uncle Jerry would sit on the porch and whittle all day long. Once he whittled me a toy boat out of a larger toy boat I had. It was almost as good as the first one, except now it had bumpy whittle marks all over it. And no paint, because he had whittled off the paint.",
    "Most of the time it was probably real bad being stuck down in a dungeon. But some days, when there was a bad storm outside, you'd look out your little window and think, 'Boy, I'm glad I'm not out in that.' ",
    "One thing vampire children have to be taught early on is, don't run with a wooden stake.",
    "Consider the daffodil. And while you're doing that, I'll be over here, looking through your stuff.",
    "Somebody told me how frightening it was how much topsoil we are losing each year, but I told that story around the campfire and nobody got scared.",
    "When I found the skull in the woods, the first thing I did was call the police. But then I got curious about it. I picked it up, and started wondering who this person was, and why he had deer horns.",
    "For mad scientists who keep brains in jars, here's a tip: why not add a slice of lemon to each jar, for freshness?",
    "The tired and thirsty prospector threw himself down at the edge of the watering hole and started to drink. But then he looked around and saw skulls and bones everywhere. ' Uh-oh,' he thought. 'This watering hole is reserved for skeletons.' ",
    "To me, it's a good idea to always carry two sacks of something when you walk around. That way, if anybody says, 'Hey, can you give me a hand?' You can say, 'Sorry, got these sacks.'",
    "The face of a child can say it all, especially the mouth part of the face.",
    "I believe in making the world safe for our children, but not our children's children, because I don't think children should be having sex.",
    "To me, boxing is like a ballet, except there's no music, no choreography and the dancers hit each other.",
    "I hope if dogs ever take over the world and they choose a king, they don't just go by size, because I bet there are some Chihuahuas with some good ideas.",
    "I think a good gift for the President would be a chocolate revolver. And since he's so busy, you'd probably have to run up to him real quick and hand it to him.",
    "Maybe in order to understand mankind we have to look at that word itself. MANKIND. Basically, it's made up of two separate words 'mank' and 'ind.' What do these words mean? It's a mystery and that's why so is mankind.",
    "If you go flying back through time and you see somebody else flying forward into the future, it's probably best to avoid eye contact.",
    "I hope some animal never bores a hole in my head and lays its eggs in my brain, because later you might think you're having a good idea but it's just eggs hatching.",
    "If you're a cowboy and you're dragging a guy behind your horse, I bet it would really make you mad if you looked back and the guy was reading a magazine.",
    "If your friend is already dead, and being eaten by vultures, I think it's okay to feed some bits of your friend to one of the vultures, to teach him to do so/me tricks. But only if you're serious about adopting the vulture.",
  ];

  onMount(async () => {
    randomQuote = quotes[Math.floor(Math.random() * quotes.length)];
  });
</script>

<svelte:head>
  <title>Deep Thoughts</title>
</svelte:head>

<main>
  <div class="quote">
    <p>{randomQuote}</p>
    <p class="sig">
      -- <a href="https://en.wikipedia.org/wiki/Jack_Handey">Jack Handey</a> +
      <a href="https://apod.nasa.gov/apod/astropix.html">APOD</a>
    </p>
    <div class="contact">
      <span class="icon"><Icon icon="icon-park-outline:twitter" /></span>
      <a href="https://twitter.com/zpeters">@zpeters</a>
    </div>
  </div>
</main>

<style>
  @import url("https://fonts.googleapis.com/css?family=Caveat");

  :global(body) {
    background-image: url("/img/S106_Mishra_1947.jpg");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: "center center";
    background-size: cover;
  }

  .quote {
    max-width: 500px;
    margin: auto;
    font-family: "Caveat";
    font-weight: bold;
    font-size: 48px;
    color: snow;
    background-color: rgba(0, 0, 0, 0.6);
    padding-top: 40px;
    padding-bottom: 40px;
    padding-left: 100px;
    padding-right: 100px;
    margin-top: 40px;
  }
  .sig a:visited {
    color: steelblue;
  }
  .sig a:link {
    color: steelblue;
  }
  .sig a:hover {
    color: cyan;
  }
  .sig a:active {
    color: cyan;
  }
  .contact {
    text-align: right;
    font-size: 30px;
  }
  .contact a:visited {
    color: rgb(159, 153, 153);
  }
  .contact a:link {
    color: rgb(159, 153, 153);
  }
  .contact a:hover {
    color: rgb(159, 153, 153);
  }
  .contact a:active {
    color: rgb(159, 153, 153);
  }
  .contact .icon {
    color: rgb(113, 163, 171);
  }
</style>
